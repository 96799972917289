/* eslint-disable no-undef */
function initVideoPostClick() {
  const videoItems = document.querySelectorAll( '.video-item' );
  videoItems.forEach( item => {
    item.addEventListener( 'click', () => {
      if ( !item.classList.contains( 'active' ) ) {
        document.querySelector( '.e-stn--product-tour' ).classList.add( 'loading' );
        document.querySelector( '.e-stn--product-tour' ).scrollIntoView( { behavior: 'smooth', block: 'start' } );
        fetchVideoPostData( item );
      }
    } );
  } );
}

async function fetchVideoPostData( item ) {
  try {
    const videoItems = document.querySelectorAll( '.video-item' );
    if ( item !== null ) {
      videoItems.forEach( i => i.classList.remove( 'active' ) );
      item.classList.add( 'active' );
    }
    const videoContent = item.querySelector( '.e-div--video-item-content' ).innerHTML;
    document.querySelector( '.e-div--video-detail' ).innerHTML = videoContent;
    document.querySelector( '.e-stn--product-tour' ).classList.remove( 'loading' );
  } catch ( error ) {
    document.querySelector( '.e-div--video-detail' ).innerHTML = 
        '<p class="error-message">Request failed. Please try again.</p>';
    document.querySelector( '.e-stn--product-tour' ).classList.remove( 'loading' );
    console.error( 'Error:', error );
  }
}

function loadNextVideo() {
  const nextBtn = document.querySelector( '.e-btn--white-outline-button-with-next-button' );
  if ( nextBtn ) {
    nextBtn.addEventListener( 'click', ( e ) => {
      e.preventDefault();
      document.querySelector( '.e-stn--product-tour' ).classList.add( 'loading' );
      document.querySelector( '.e-stn--product-tour' ).scrollIntoView( { behavior: 'smooth', block: 'start' } );
      const activeItem = document.querySelector( '.video-item.active' );      
      let currentIndex = activeItem ? activeItem.getAttribute( 'data-index' ) : 0;
      const videoItems = document.querySelectorAll( '.video-item' );
      currentIndex = parseInt( currentIndex ) + 1;
      if ( parseInt( currentIndex ) >= videoItems.length ) {
        currentIndex = 0;
      }
      const nextItem = videoItems[parseInt( currentIndex )];
      fetchVideoPostData( nextItem );
    } );
  }
}

document.addEventListener( 'DOMContentLoaded', () => {
  initVideoPostClick();
  const firstActiveItem = document.querySelector( '.video-item.active' );
  if ( firstActiveItem ) {
    document.querySelector( '.e-stn--product-tour' ).classList.add( 'loading' );
    fetchVideoPostData( firstActiveItem );
  }
  loadNextVideo();
} );